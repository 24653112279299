import { IAnimation, StepBehaviorType } from './type';

export const SWIPE_CONFIDENCE_THRESHOLD = 10000;
export const swipePower = (offset: number, velocity: number) => {
  return Math.abs(offset) * velocity;
};
export const defaultVariants = {
  enter: ({
    stepBehavior,
    direction,
    animation,
    transitionDuration,
  }: IAnimation) => {
    const isHorizontal = direction === 'x';
    const isPrev = stepBehavior === StepBehaviorType.PREV;
    const isFade = animation === 'fade';
    const isEase = !!animation && animation !== 'fade';
    function x() {
      if (isFade || !isHorizontal) {
        return 0;
      } else {
        return isPrev ? -1000 : 1000;
      }
    }
    function y() {
      if (isFade || isHorizontal) {
        return 0;
      } else {
        return isPrev ? -1000 : 1000;
      }
    }
    return {
      x: x(),
      y: y(),
      opacity: 0,
      transition: {
        ease: isEase && animation,
        duration: transitionDuration,
      },
    };
  },
  center: ({ animation, transitionDuration }: IAnimation) => {
    const isEase = !!animation && animation !== 'fade';
    return {
      x: 0,
      y: 0,
      opacity: 1,
      transition: {
        ease: isEase && animation,
        duration: transitionDuration,
      },
    };
  },
  exit: ({
    stepBehavior,
    direction,
    animation,
    transitionDuration,
  }: IAnimation) => {
    const isHorizontal = direction === 'x';
    const isPrev = stepBehavior === StepBehaviorType.PREV;
    const isFade = animation === 'fade';
    const isEase = !!animation && animation !== 'fade';
    function x() {
      if (isFade || !isHorizontal) {
        return 0;
      } else {
        return isPrev ? 1000 : -1000;
      }
    }
    function y() {
      if (isFade || isHorizontal) {
        return 0;
      } else {
        return isPrev ? 1000 : -1000;
      }
    }
    return {
      x: x(),
      y: y(),
      opacity: 0,
      transition: {
        ease: isEase && animation,
        duration: transitionDuration,
      },
    };
  },
};
