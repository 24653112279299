import { css, cx } from '@emotion/css';
import {
  AnimatedWrapper,
  Indicator,
} from '@topremit/shared-web/components/elements';
import {
  usePrevious,
  useTranslation,
  useWindowSize,
} from '@topremit/shared-web/hooks';
import { AnimatePresence } from 'framer-motion';
import {
  cloneElement,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import {
  SWIPE_CONFIDENCE_THRESHOLD,
  defaultVariants,
  swipePower,
} from './constant';
import { ISlideShowProps, StepBehaviorType } from './type';

function SlideShow({
  contentClassName,
  repeatStep = false,
  autoPlay = false,
  autoPlayTime = 2000,
  draggable,
  direction = 'x',
  className,
  nextButton,
  prevButton,
  showStep,
  totalData,
  stepClassName,
  onChange,
  indicator,
  indicatorClassName,
  value,
  children,
  customVariants,
  animation,
  transitionDuration = 0.2,
  dragConstraints = { left: 2, right: 2, top: 2, bottom: 2 },
  dragElastic = 1,
  actionButtonPrev,
  actionButtonNext,
  showActionButton = false,
}: ISlideShowProps) {
  const [stepBehavior, setStepBehavior] = useState<StepBehaviorType>(
    StepBehaviorType.NEXT,
  );
  const [current, setCurrent] = useState(value || 0);
  const [finalCurrent, setFinalCurrent] = useState(current);
  const { t } = useTranslation();
  const [enableNext, setEnableNext] = useState(true);
  const canDrag = draggable && totalData > 1;
  const isFirstStep = current === 0;
  const isLastStep = current === totalData - 1;
  const variants = customVariants || defaultVariants;
  const prevCurrent = usePrevious(current);
  const innerWrapperRef = useRef<HTMLDivElement>(null);
  const [screenWidth] = useWindowSize();

  function renderChildren() {
    const childrenIsArray = Array.isArray(children);
    return childrenIsArray ? children[finalCurrent] : children;
  }

  const stepHandler = useCallback(
    (_animationdirection: StepBehaviorType) => {
      setEnableNext(false);
      const isNextStep = _animationdirection === StepBehaviorType.NEXT;
      setStepBehavior(_animationdirection);
      if (isNextStep) {
        if (isLastStep && repeatStep) {
          setCurrent(0);
        }
        if (!isLastStep) {
          setCurrent((prev) => prev + 1);
        }
      } else {
        if (isFirstStep && repeatStep) {
          setCurrent(totalData - 1);
        }
        if (!isFirstStep) {
          setCurrent((prev) => prev - 1);
        }
      }
    },
    [isFirstStep, isLastStep, repeatStep, totalData],
  );

  function onDragEnd(_e, { offset, velocity }) {
    const swipeHorizontal = swipePower(offset.x, velocity.x);
    const swipeVertical = swipePower(offset.y, velocity.y);
    const swipe = direction === 'x' ? swipeHorizontal : swipeVertical;
    if (swipe < -SWIPE_CONFIDENCE_THRESHOLD) {
      stepHandler(StepBehaviorType.NEXT);
    } else if (swipe > SWIPE_CONFIDENCE_THRESHOLD) {
      stepHandler(StepBehaviorType.PREV);
    }
  }

  function indicatorHandler(newCurrent: number) {
    setStepBehavior(
      finalCurrent > newCurrent ? StepBehaviorType.PREV : StepBehaviorType.NEXT,
    );
    setCurrent(newCurrent);
  }
  const toNext = useCallback(() => {
    enableNext && stepHandler(StepBehaviorType.NEXT);
  }, [enableNext, stepHandler]);
  const toPrev = useCallback(() => {
    enableNext && stepHandler(StepBehaviorType.PREV);
  }, [enableNext, stepHandler]);

  const leftActionButton = useMemo(() => {
    if (!showActionButton || !actionButtonPrev) {
      return null;
    }
    return cloneElement(actionButtonPrev);
  }, [actionButtonPrev, showActionButton]);

  const rightActionButton = useMemo(() => {
    if (!showActionButton || !actionButtonNext) {
      return null;
    }
    return cloneElement(actionButtonNext);
  }, [actionButtonNext, showActionButton]);

  useEffect(() => {
    onChange?.(finalCurrent);
  }, [finalCurrent, onChange]);

  useEffect(() => {
    setFinalCurrent(current);
  }, [current]);

  useEffect(() => {
    if (typeof value !== 'undefined' && value !== prevCurrent) {
      setStepBehavior(
        finalCurrent > value ? StepBehaviorType.PREV : StepBehaviorType.NEXT,
      );
      setCurrent(value);
    }
  }, [value]);

  useEffect(() => {
    nextButton?.addEventListener('click', toNext);
    return () => {
      nextButton?.removeEventListener('click', toNext);
    };
  }, [nextButton, toNext]);

  useEffect(() => {
    prevButton?.addEventListener('click', toPrev);
    return () => {
      prevButton?.removeEventListener('click', toPrev);
    };
  }, [prevButton, toPrev]);

  useEffect(() => {
    if (autoPlay) {
      const interval = setInterval(() => {
        stepHandler(StepBehaviorType.NEXT);
      }, autoPlayTime);
      return () => clearInterval(interval);
    }
  }, [current, autoPlay, autoPlayTime, stepHandler]);

  useEffect(() => {
    if (!prevButton || !nextButton) {
      return;
    }

    const slideShowContent = Array.prototype.slice
      .call(nextButton.parentElement?.children)
      .find((el) => el.className.includes('slide-show-inner')) as HTMLElement;

    if (!slideShowContent) {
      return;
    }

    const slideShowContentFirstElementChild =
      slideShowContent.firstElementChild;

    if (!slideShowContentFirstElementChild) {
      return;
    }

    prevButton.style.top = `${
      slideShowContentFirstElementChild.clientHeight / 2
    }px`;

    nextButton.style.top = `${
      slideShowContentFirstElementChild.clientHeight / 2
    }px`;
  }, [screenWidth, prevButton, nextButton]);

  return (
    <div className={cx(styled.root, 'slide-show', className)}>
      <div ref={innerWrapperRef} className="slide-show-outer">
        {leftActionButton}
        <div className="slide-show-inner">
          <AnimatePresence
            mode="wait"
            initial={false}
            custom={{ stepBehavior, direction, animation, transitionDuration }}
          >
            <AnimatedWrapper
              htmlTag="div"
              key={finalCurrent}
              custom={{
                stepBehavior,
                direction,
                animation,
                transitionDuration,
              }}
              initial="enter"
              animate="center"
              exit="exit"
              variants={variants}
              onAnimationComplete={() => setEnableNext(true)}
              className={cx(
                styled.innerWrapper(!!canDrag),
                'slide-show-content',
                contentClassName,
              )}
              drag={canDrag && direction}
              dragConstraints={dragConstraints}
              dragElastic={dragElastic}
              onDragEnd={onDragEnd}
            >
              {renderChildren()}
            </AnimatedWrapper>
          </AnimatePresence>
        </div>
        {rightActionButton}
      </div>

      {showStep && totalData > 1 && (
        <div className={stepClassName}>
          {t('announcement.step', {
            current: current + 1,
            total: totalData,
          })}
        </div>
      )}
      {indicator && totalData > 1 && (
        <Indicator
          max={totalData}
          current={current}
          className={cx('slide-show-indicator', indicatorClassName)}
          onClick={indicatorHandler}
        />
      )}
    </div>
  );
}

export default memo(SlideShow);

const styled = {
  root: css`
    .slide-show-outer {
      position: relative;
      width: fit-content;
      display: flex;
      justify-content: center;
      align-items: center;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none;
      &:-webkit-scrollbar {
        display: none;
      }
    }
    .slide-show-inner {
      overflow: hidden;
    }
    .slide-show-indicator {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 0.625rem;
    }
  `,
  innerWrapper: (draggable: boolean) => css`
    cursor: ${draggable ? 'grab' : 'unset'};
  `,
};
